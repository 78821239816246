import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

function SpecFilter(props) {
  const [playerSpecs, setPlayerSpecs] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState();

  const getPlayerSpecs = () => {
    const playerSpecList = {};

    props.upgrades.forEach((upgrade) => {
      upgrade.bossDrops.forEach((bossDrop) => {
        bossDrop.playerUpgrades.forEach((playerUpgrade) => {
          const existingPlayer = playerSpecList[playerUpgrade.name];
          if (existingPlayer) {
            const existingSpec = existingPlayer.find(
              (spec) => spec === playerUpgrade.spec
            );

            if (!existingSpec) {
              existingPlayer.push(playerUpgrade.spec);
            }
          } else {
            playerSpecList[playerUpgrade.name] = [playerUpgrade.spec];
          }
        });
      });
    });

    return playerSpecList;
  };

  const changeSelectedPlayer = (name) => {
    const specs = playerSpecs[name];
    if (specs) {
      setSelectedPlayer({ name, specs });
    } else {
      setSelectedPlayer(undefined);
    }
  };

  const changePlayerSpec = (event) => {
    let selectedPlayerSpecs = props.selectedPlayerSpecs;

    selectedPlayerSpecs = {
      ...selectedPlayerSpecs,
      [selectedPlayer.name]: event.target.value,
    };

    props.setSelectedPlayerSpecs(selectedPlayerSpecs);
  };

  const specIsChecked = (name, spec) => {
    return props.selectedPlayerSpecs[name] === spec;
  };

  useEffect(() => {
    const playerSpecs = getPlayerSpecs();
    let localSelectedSpecs = {};

    Object.keys(playerSpecs).forEach((specKey) => {
      localSelectedSpecs[specKey] = playerSpecs[specKey][0];
    });

    props.setSelectedPlayerSpecs(localSelectedSpecs);

    setPlayerSpecs(playerSpecs);
  }, [props.upgrades]);

  return (
    <>
      <Form.Select
        aria-label="Default select example"
        value={props.difficulty}
        onChange={(event) => changeSelectedPlayer(event.target.value)}
        style={{ marginTop: "10px" }}
      >
        <option>Spec Selector</option>
        {Object.keys(playerSpecs).map(
          (playerSpec, i) =>
            playerSpecs[playerSpec]?.length > 1 && (
              <option key={`spec_${i}`} value={playerSpec}>
                {playerSpec}
              </option>
            )
        )}
      </Form.Select>
      <div style={{ marginLeft: "10px" }}>
        {selectedPlayer?.specs.map((spec, i) => {
          return (
            <div key={`${spec}_i`}>
              <input
                id={`${selectedPlayer.name}_${spec}`}
                value={spec}
                type="radio"
                style={{ marginRight: "5px" }}
                onChange={changePlayerSpec}
                checked={specIsChecked(selectedPlayer.name, spec)}
              />
              <label>{spec}</label>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SpecFilter;
