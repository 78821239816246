import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { colorDictionary } from "../utils/color-dictionary";
import { slotDictionary } from "../utils/slot-dictionary";
import GearTooltip from "./gearTooltip";
import { startCase } from "lodash";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function Player(props) {
  const player = props.player;
  const [currentPiece, setCurrentPiece] = useState();
  const [bisItem, setBisItem] = useState();

  const renderTooltip = (tooltipProps) => {
    <Tooltip id="button-tooltip" {...tooltipProps}>
      Simple tooltip
    </Tooltip>;
  };

  const parsePiece = (gear, slotStr) => {
    if (Array.isArray(slotStr)) {
      let multiGear = [];
      slotStr.forEach((x) => multiGear.push(player.gear[x]));
      return multiGear;
    }

    const playerGear = gear[slotStr];

    if (!playerGear) {
      return null;
    }

    return {
      itemName: startCase(playerGear.name),
      ilvl: playerGear.ilevel,
      stats: {
        int: playerGear["intellect"],
        str: playerGear["strength"],
        agi: playerGear["agility"],
        agiint: playerGear["agiint"],
        stragi: playerGear["stragi"],
        strint: playerGear["strint"],
        stragiint: playerGear["stragiint"],
        haste: playerGear["haste_rating"],
        crit: playerGear["haste_rating"],
        mastery: playerGear["mastery_rating"],
        vers: playerGear["versatility_rating"],
        stam: playerGear["stamina"],
      },
    };
  };

  const getBis = () => {
    const { percentUpgrade, difficulty, slotId, name, spec } = props.player;

    for (let i = 0; i < props.allUpgrades.length; i++) {
      const boss = props.allUpgrades[i];
      for (let j = 0; j < boss.bossDrops.length; j++) {
        const item = boss.bossDrops[j];

        const higherItem = item.playerUpgrades.find(
          (x) =>
            x.difficulty === difficulty &&
            x.slotId === slotId &&
            x.name === name &&
            x.spec === spec &&
            x.percentUpgrade > percentUpgrade
        );

        if (higherItem) {
          return {
            itemName: higherItem.itemName,
            bossName: higherItem.bossName,
            percentUpgrade: higherItem.percentUpgrade,
            dpsUpgrade: higherItem.dpsUpgrade,
            ilvl: higherItem.ilvl,
          };
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const slotStr = slotDictionary[player.slotId];

    if (Array.isArray(slotStr)) {
      setCurrentPiece(slotStr.map((x) => parsePiece(player.gear, x)));
    } else {
      setCurrentPiece(parsePiece(player.gear, slotStr));
    }

    setBisItem(getBis());
  }, [props.player]);

  return (
    <Card
      style={{
        padding: "15px",
        width: "100%",
      }}
    >
      <Card.Body>
        <Container fluid>
          <Row>
            <Col xs lg="2">
              <Row
                style={{
                  color: colorDictionary[player.classParam.replace(/\s/g, "")],
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                {player.name} ({player.spec})
              </Row>
              <Row style={{ fontSize: "12px" }}>
                Last Updated:{" "}
                {new Date(player.lastUpdated).toLocaleString("default", {
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Row>
            </Col>
            <Col xs lg="2">
              <Row>Percent Upgrade: {player.percentUpgrade}%</Row>
              <Row>DPS Upgrade: {player.dpsUpgrade}</Row>
            </Col>
            <Col xs lg="3">
              BiS:{" "}
              <span>
                {bisItem ? (
                  <>
                    <span>No </span>
                    <Tippy
                      content={<GearTooltip piece={bisItem}></GearTooltip>}
                    >
                      <span style={{ color: "#a335ee" }}>
                        {bisItem.itemName}
                      </span>
                    </Tippy>
                  </>
                ) : (
                  <span style={{ color: "green" }}>Yes</span>
                )}
              </span>
            </Col>
            <Col xs lg="5">
              {currentPiece ? (
                Array.isArray(currentPiece) ? (
                  <>
                    {"Equipped: "}
                    <Tippy
                      content={
                        <GearTooltip piece={currentPiece[0]}></GearTooltip>
                      }
                    >
                      <span style={{ color: "#a335ee" }}>
                        {currentPiece[0].itemName}
                      </span>
                    </Tippy>
                    <span>, </span>
                    <Tippy
                      content={
                        <GearTooltip piece={currentPiece[1]}></GearTooltip>
                      }
                    >
                      <span style={{ color: "#a335ee" }}>
                        {currentPiece[1].itemName}
                      </span>
                    </Tippy>
                  </>
                ) : (
                  <>
                    {"Equipped: "}
                    <Tippy
                      content={<GearTooltip piece={currentPiece}></GearTooltip>}
                    >
                      <span style={{ color: "#a335ee" }}>
                        {currentPiece?.itemName}
                      </span>
                    </Tippy>
                  </>
                )
              ) : (
                <span>No equivalent piece equipped</span>
              )}
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default Player;
