import React, { useState } from "react";
import { Form, Dropdown, Nav, Container, Button } from "react-bootstrap";
import SpecFilter from "./specFilter";

function Sidebar(props) {
  const toggleCollapse = () => {
    props.setCollapsed(!props.collapsed);
  };
  return (
    <Nav className={"col-md-12 d-none d-md-block sidebar sidebar-expanded"}>
      {!props.collapsed && (
        <Nav.Item style={{ marginTop: "15px" }}>
          <Form.Select
            aria-label="Default select example"
            value={props.difficulty}
            onChange={(event) => props.setDifficulty(event.target.value)}
          >
            <option value="Normal">Normal</option>
            <option value="Heroic">Heroic</option>
            <option value="Mythic">Mythic</option>
          </Form.Select>
        </Nav.Item>
      )}
      {!props.collapsed && (
        <Nav.Item style={{ marginTop: "15px" }}>
          <SpecFilter
            upgrades={props.upgrades}
            selectedPlayerSpecs={props.selectedPlayerSpecs}
            setSelectedPlayerSpecs={props.setSelectedPlayerSpecs}
          ></SpecFilter>
        </Nav.Item>
      )}

      {/* <Accordion alwaysOpen>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Specs</Accordion.Header>
          <Accordion.Body>Heroic</Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
    </Nav>
  );
}

export default Sidebar;
