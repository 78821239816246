export const bossDictionary = [
  {
    name: "Vigilant Guardian",
    id: 2458,
    order: 1,
  },
  {
    name: "Skolex, the Insatiable Ravener",
    id: 2465,
    order: 2,
  },
  {
    name: "Artificer Xy'mox",
    id: 2470,
    order: 3,
  },
  {
    name: "Dausegne, the Fallen Oracle",
    id: 2459,
    order: 4,
  },
  {
    name: "Prototype Pantheon",
    id: 2460,
    order: 5,
  },
  {
    name: "Lihuvim, Principal Architect",
    id: 2461,
    order: 6,
  },
  {
    name: "Halondrus the Reclaimer",
    id: 2463,
    order: 7,
  },
  {
    name: "Anduin Wrynn",
    id: 2469,
    order: 8,
  },
  {
    name: "Lords of Dread",
    id: 2457,
    order: 9,
  },
  {
    name: "Rygelon",
    id: 2467,
    order: 10,
  },
  {
    name: "The Jailer",
    id: 2464,
    order: 11,
  },
  {
    name: "Trash Drop",
    id: -24,
    order: 12,
  },
];
