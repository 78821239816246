import React, { useState, useEffect } from "react";
import Drop from "./drop";
import Accordion from "react-bootstrap/Accordion";

function Boss(props) {
  const copyBossDrops = JSON.parse(JSON.stringify(props.boss.bossDrops));

  let filteredBossDrops = copyBossDrops.filter(
    (x) => x.difficulty === props.difficulty
  );

  filteredBossDrops.forEach((bossDropLog) => {
    bossDropLog.playerUpgrades = bossDropLog.playerUpgrades.filter((x) => {
      if (
        !props.selectedPlayerSpecs ||
        Object.keys(props.selectedPlayerSpecs).length === 0
      ) {
        return true;
      }

      //return true;

      return props.selectedPlayerSpecs[x.name] === x.spec;
    });
  });

  filteredBossDrops = filteredBossDrops.filter(
    (x) => x.playerUpgrades.length > 0
  );

  return (
    <>
      <Accordion alwaysOpen>
        {filteredBossDrops.length > 0 ? (
          filteredBossDrops.map((item, i) => (
            <Accordion.Item eventKey={`${i + 1}`} key={`drop_${i}`}>
              <Accordion.Header>
                {item.itemName} ({item.difficulty})
              </Accordion.Header>
              <Accordion.Body>
                <Drop
                  playerUpgrades={item.playerUpgrades}
                  allUpgrades={props.allUpgrades}
                  boss={props.boss}
                  selectedPlayerSpecs={props.selectedPlayerSpecs}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))
        ) : (
          <span style={{ fontWeight: "bold" }}>No Upgrades Found</span>
        )}
      </Accordion>
    </>
  );
}

export default Boss;
