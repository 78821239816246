import React, { useState, useEffect } from "react";
import Player from "./player";

function Drop(props) {
  const items = props.playerUpgrades.sort(
    (a, b) => b.percentUpgrade - a.percentUpgrade
  );

  return (
    <>
      {items.map((upgrade, i) => (
        <Player
          key={`player_${i}`}
          player={upgrade}
          boss={props.boss}
          allUpgrades={props.allUpgrades}
          selectedPlayerSpecs={props.selectedPlayerSpecs}
        />
      ))}
    </>
  );
}

export default Drop;
