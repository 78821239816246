import { bossDictionary } from "./boss-dictionary";
import { tierDictionary } from "./tier-dictionary";

const parseUpgrades = (upgrades) => {
  const upgradeData = upgrades.map((playerUpgrade) => {
    const data = JSON.parse(playerUpgrade.upgrades_json);

    return {
      name: data.name,
      spec:
        data.spec.split(" ")[0] === "Beast"
          ? "Beast Mastery"
          : data.spec.substr(0, data.spec.indexOf(" ")), //First word is spec
      classParam:
        data.spec.split(" ")[0] === "Beast"
          ? "Hunter"
          : data.spec.substr(data.spec.indexOf(" ") + 1), //Everything after is class (Demon Hunter)
      lastUpdated: playerUpgrade.modify_dt,
      bosses: data.bosses,
      gear: data.gear,
      difficulty: playerUpgrade.difficulty,
    };
  });

  const bossDrops = bossDictionary.map((boss) => {
    //TODO: Manually add every drop a boss has
    return {
      bossName: boss.name,
      bossId: boss.id,
      bossDrops: [],
    };
  });

  upgradeData.forEach((player) => {
    const { name, spec, classParam, lastUpdated, difficulty, gear } = player;
    //For every boss that the player gets upgrades from
    player.bosses.forEach((boss) => {
      const listedBoss = bossDrops.find((x) => x.bossId === boss.bossId);
      //For every upgrade for a particular boss per player
      boss.upgrades.forEach((upgrade) => {
        //Add an upgrade for this player to the relevant boss in the master drop list
        if (!listedBoss) {
          return;
        }

        const dropEntry = {
          name,
          spec,
          classParam,
          gear,
          lastUpdated,
          difficulty,
          ilvl: upgrade.ilvl,
          slotId: upgrade.slotId,
          percentUpgrade: upgrade.percentUpgrade,
          dpsUpgrade: upgrade.dpsUpgrade,
          itemName: upgrade.itemName,
          itemId: upgrade.itemId,
          bossName: listedBoss.bossName,
        };

        const tierName = tierDictionary[upgrade.itemName];
        if (tierName) {
          console.log(
            "TIER: ",
            difficulty,
            " - ",
            listedBoss.bossName,
            " - ",
            tierName
          );
        }

        const bossItem = tierName
          ? listedBoss.bossDrops.find(
              (x) => x.itemName === tierName && x.ilvl === upgrade.ilvl
            )
          : listedBoss.bossDrops.find(
              (x) => x.itemId === upgrade.itemId && x.ilvl === upgrade.ilvl
            );

        if (!bossItem) {
          const item = {
            itemName: tierName ? tierName : upgrade.itemName,
            itemId: upgrade.itemId,
            ilvl: upgrade.ilvl,
            difficulty: difficulty,
            slotId: 12,
            playerUpgrades: [dropEntry],
          };

          listedBoss.bossDrops.push(item);
        } else {
          bossItem.playerUpgrades.push(dropEntry);
        }
      });
    });
  });

  return bossDrops;
};

export default parseUpgrades;
