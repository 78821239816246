import React, { useState } from "react";

function GearTooltip(props) {
  const item = props.piece;
  return (
    <>
      <div style={{ fontWeight: "bold" }}>{item.itemName}</div>
      <div>
        <span style={{ fontWeight: "bold" }}>iLvl: </span>
        {item.ilvl}
      </div>
      {item.stats && (
        <div>
          {item.stats.agi && <div>Agility: {item.stats.agi}</div>}
          {item.stats.agiint && <div>Agi/Int: {item.stats.agiint}</div>}
          {item.stats.str && <div>Strength: {item.stats.str}</div>}
          {item.stats.stragi && <div>Str/Agi: {item.stats.stragi}</div>}
          {item.stats.stragiint && (
            <div>Str/Agi/Int: {item.stats.stragiint}</div>
          )}
          {item.stats.strint && <div>Str/Int: {item.stats.strint}</div>}
          {item.stats.stam && <div>Stam: {item.stats.stam}</div>}
          {item.stats.crit && <div>Crit: {item.stats.crit}</div>}
          {item.stats.haste && <div>Haste: {item.stats.haste}</div>}
          {item.stats.vers && <div>Vers: {item.stats.vers}</div>}
          {item.stats.mast && <div>Mast: {item.stats.mast}</div>}
        </div>
      )}
      {item.percentUpgrade && (
        <div style={{ fontSize: "12px", marginTop: "5px" }}>
          <div>Percent Upgrade: {item.percentUpgrade}</div>
          <div>DPS Upgrade: {item.dpsUpgrade}</div>
        </div>
      )}
      {item.bossName && (
        <div style={{ fontSize: "12px" }}>Drops from: {item.bossName}</div>
      )}
    </>
  );
}

export default GearTooltip;
