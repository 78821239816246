export const tierDictionary = {
  //Death Knight
  ["Visage of the First Eidolon"]: "Tier Head",
  ["Shoulderplates of the First Eidolon"]: "Tier Shoulders",
  ["Carapace of the First Eidolon"]: "Tier Chest",
  ["Chausses of the First Eidolon"]: "Tier Legs",
  ["Gauntlets of the First Eidolon"]: "Tier Gloves",
  //Demon Hunter
  ["Mercurial Punisher's Hood"]: "Tier Head",
  ["Mercurial Punisher's Shoulderpads"]: "Tier Shoulders",
  ["Mercurial Punisher's Jerkin"]: "Tier Chest",
  ["Mercurial Punisher's Breeches"]: "Tier Legs",
  ["Mercurial Punisher's Grips"]: "Tier Gloves",
  //Druid
  ["Headpiece of the Fixed Stars"]: "Tier Head",
  ["Shoulderpads of the Fixed Stars"]: "Tier Shoulders",
  ["Chestguard of the Fixed Stars"]: "Tier Chest",
  ["Leggings of the Fixed Stars"]: "Tier Legs",
  ["Handwraps of the Fixed Stars"]: "Tier Gloves",
  //Hunter
  ["Godstalker's Sallet"]: "Tier Head",
  ["Godstalker's Pauldrons"]: "Tier Shoulders",
  ["Godstalker's Hauberk"]: "Tier Chest",
  ["Godstalker's Tassets"]: "Tier Legs",
  ["Godstalker's Gauntlets"]: "Tier Gloves",
  //Mage
  ["Erudite Occultist's Hood"]: "Tier Head",
  ["Erudite Occultist's Mantle"]: "Tier Shoulders",
  ["Erudite Occultist's Robes"]: "Tier Chest",
  ["Erudite Occultist's Leggings"]: "Tier Legs",
  ["Erudite Occultist's Handwraps"]: "Tier Gloves",
  //Monk
  ["Crown of the Grand Upwelling"]: "Tier Head",
  ["Tassets of the Grand Upwelling"]: "Tier Shoulders",
  ["Cuirass of the Grand Upwelling"]: "Tier Chest",
  ["Legguards of the Grand Upwelling"]: "Tier Legs",
  ["Grips of the Grand Upwelling"]: "Tier Gloves",
  //Paladin
  ["Luminous Chevalier's Casque"]: "Tier Head",
  ["Luminous Chevalier's Epaulettes"]: "Tier Shoulders",
  ["Luminous Chevalier's Plackart"]: "Tier Chest",
  ["Luminous Chevalier's Robes"]: "Tier Legs",
  ["Luminous Chevalier's Gauntlets"]: "Tier Gloves",
  //Priest
  ["Amice of the Empyrean"]: "Tier Head",
  ["Capelet of the Empyrean"]: "Tier Shoulders",
  ["Habit of the Empyrean"]: "Tier Chest",
  ["Leggings of the Empyrean"]: "Tier Legs",
  ["Caress of the Empyrean"]: "Tier Gloves",
  //Rogue
  ["Soulblade Guise"]: "Tier Head",
  ["Soulblade Nightwings"]: "Tier Shoulders",
  ["Soulblade Leathers"]: "Tier Chest",
  ["Soulblade Leggings"]: "Tier Legs",
  ["Soulblade Grasps"]: "Tier Gloves",
  //Shaman
  ["Theurgic Starspeaker's Howl"]: "Tier Head",
  ["Theurgic Starspeaker's Adornment"]: "Tier Shoulders",
  ["Theurgic Starspeaker's Ringmail"]: "Tier Chest",
  ["Theurgic Starspeaker's Tassets"]: "Tier Legs",
  ["Theurgic Starspeaker's Runebindings"]: "Tier Gloves",
  //Warlock
  ["Horns of the Demon Star"]: "Tier Head",
  ["Mantle of the Demon Star"]: "Tier Shoulders",
  ["Robes of the Demon Star"]: "Tier Chest",
  ["Leggings of the Demon Star"]: "Tier Legs",
  ["Grasps of the Demon Star"]: "Tier Gloves",
  //Warrior
  ["Gaze of the Infinite Infantry"]: "Tier Head",
  ["Pauldrons of the Infinite Infantry"]: "Tier Shoulders",
  ["Breastplate of the Infinite Infantry"]: "Tier Chest",
  ["Legplates of the Infinite Infantry"]: "Tier Legs",
  ["Grasps of the Infinite Infantry"]: "Tier Gloves",
};
