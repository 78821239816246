//TODO: FINISH

export const slotDictionary = {
  1: "head",
  2: "neck",
  3: "shoulders",
  //4 is shirt
  5: "chest",
  6: "waist",
  7: "legs",
  8: "feet",
  9: "wrists",
  10: "hands",
  11: ["finger1", "finger2"],
  12: ["trinket1", "trinket2"],
  13: "main_hand", // weapon
  14: "off_hand", // shield
  15: "main_hand",
  16: "back",
  17: "main_hand", // two-hand
  // 18 is bags
  //19 is tabard
  20: "chest",
  21: "main_hand",
  22: "off_hand",
  23: "off_hand",
  // 24 is ammo
  // 25 is thrown
  26: "main_hand", // some ranged weapons
  // 27 is quiver
  28: "off_hand", // relic
};
