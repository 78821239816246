import React, { useState, useEffect } from "react";
import Boss from "./boss";
import Accordion from "react-bootstrap/Accordion";

function Bosses(props) {
  return (
    <Accordion alwaysOpen>
      {props.upgrades.map((boss, i) => (
        <Accordion.Item eventKey={`${i + 1}`} key={`boss_${i}`}>
          <Accordion.Header>{boss.bossName}</Accordion.Header>
          <Accordion.Body>
            <Boss
              boss={boss}
              difficulty={props.difficulty}
              allUpgrades={props.upgrades}
              selectedPlayerSpecs={props.selectedPlayerSpecs}
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default Bosses;
