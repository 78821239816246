import parseUpgrades from "../utils/parse-upgrades";
import getUpgradesApi from "./../api/upgrades";
import React, { useState, useEffect } from "react";
import Bosses from "./bosses";
import Sidebar from "./sidebar";
import { Spinner } from "react-bootstrap";

function Home() {
  const [error, setError] = useState();
  const [difficulty, setDifficulty] = useState("Mythic");
  const [upgrades, setUpgrades] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPlayerSpecs, setSelectedPlayerSpecs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getApiData().then((data) => {
      setError(data.success);
      if (data.success) {
        setUpgrades(parseUpgrades(data.response));
      }
      setLoading(false);
    });
  }, []);

  const getApiData = async () => {
    return await getUpgradesApi();
  };

  return (
    <div style={{ position: "relative" }}>
      <div className={"width-transition"}>
        <Sidebar
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          upgrades={upgrades}
          selectedPlayerSpecs={selectedPlayerSpecs}
          setSelectedPlayerSpecs={setSelectedPlayerSpecs}
        />
      </div>
      <div style={{ width: "90%", float: "right" }}>
        {loading ? (
          <Spinner
            animation="border"
            style={{ marginLeft: "50%", marginTop: "25%" }}
            size="md"
          />
        ) : (
          <Bosses
            upgrades={upgrades}
            difficulty={difficulty}
            selectedPlayerSpecs={selectedPlayerSpecs}
          ></Bosses>
        )}
      </div>
    </div>
  );
}

export default Home;
